import * as React from "react"
import { Container } from 'react-bootstrap';
import GoogleRating from "../../googleRating/GoogleRating"
import { bounceInRight, bounceInLeft } from 'react-animations';
import Radium, { StyleRoot } from 'radium';
import ScrollAnimation from 'react-animate-on-scroll';
import "./Banner.scss"
import ReactMarkdown from "react-markdown"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getSrc } from "gatsby-plugin-image"
// markup
import PropertyBanner from '@Components/MarketProperty/PropertyBanner/PropertyBanner'
import GetURL from '@Components/common/site/get-url';
import ImageTransform from "@Components/common/ggfx-client/module/components/image-transform";
import CustomVideoPlay from "./CustomVideo.js";
import { Helmet } from "react-helmet";
import { useLocation } from "@reach/router";
const styles = {
  bounceInRight: {
    animation: 'x 2s',
    animationName: Radium.keyframes(bounceInRight, 'bounceInRight')
  },
  bounceInLeft: {
    animation: 'x 2s',
    animationName: Radium.keyframes(bounceInLeft, 'bounceInLeft')
  }
}



const Banner = (props) => {

  let processedImages = JSON.stringify({});
  if (props?.imagetransforms?.Image_Transforms) {
    processedImages = props?.imagetransforms.Image_Transforms;
  }
  var settings = {
    dots: true,
    infinite: true,
    speed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
  };

  const location = useLocation();
  const thePath  = location.pathname
  return (

    <React.Fragment>
      {props.Pagename === 'Market Your Property' ? <PropertyBanner Video_Ask={props.Video_Ask} Title={props.Title}
        Banner_Content={props.Banner_Content} Banner_CTA_1_Label={props.Banner_CTA_1_Label} Banner_CTA_1_URL={props.Banner_CTA_1_URL} Banner_CTA_2_Label={props.Banner_CTA_2_Label} Banner_CTA_2_URL={props.Banner_CTA_2_URL} Banner_Image={props.Banner_Image} Right_Video_Image={props.Right_Video_Image} Embed_Video_URL={props.Embed_Video_URL} Show_Review={props.Show_Review} Show_Search={props.Show_Search}
      /> :
        <>
         {thePath =='/' &&
        <Helmet>
          <script type="application/ld+json">{`{
            "@context": "http://schema.org",
            "@type": "Product",
            "name" : "Estate Agents in Manchester",
            "image": "https://www.bentleyhurst.co.uk/images/mail-logo.png",
            "url": "https://www.bentleyhurst.co.uk/",
            "description" : "Buy, sell and rent property in and around Manchester with our estate agents in Manchester who experts in local market and latest technologies",
            "brand" : {
              "@type" : "Brand",
              "name" : "Bentley Hurst - Manchester Estate Agents",
              "image" : "https://www.bentleyhurst.co.uk/images/mail-logo.png"
            },
            "aggregateRating": {
              "@type": "AggregateRating",
              "ratingValue": "4.9",
              "reviewCount": "173",
              "bestRating": "5"
            }
          }`}</script>
        </Helmet>}
          {props.Slide ?
            <Slider {...settings}>
              {props.Slider.map((item, i) => (

                <div className="main-banner">
                  <div className="main-banner-embed">


                    <img src={getSrc(item.Slider_Image.url_sharp)} alt={item.Slider_Image.alternativeText} />

                  </div>
                  <div className="main-banner-content">
                    <Container>
                      <div className="banner-text">
                        <ScrollAnimation animateIn='fadeIn' animateOnce={true} animateOut='fadeOut'>
                        <div className="banner-heading-second">{item.Title}</div>
                          
                          
                        </ScrollAnimation>

                        <StyleRoot>
                          <div className="rich-text-editer-block">
                            <ReactMarkdown style={styles.bounceInLeft} source={item.Banner_Content} allowDangerousHtml />
                          </div>
                        </StyleRoot>

                        <StyleRoot>
                          <div className="btn-wrap" style={styles.bounceInRight}>
                            {item.Banner_CTA_1_Label !== null && item.Banner_CTA_1_URL !== null &&
                              <GetURL label={item.Banner_CTA_1_Label} alias={item.Banner_CTA_1_URL?.Alias} class={thePath=='/' ?'glow-on-hover':'btn btn-primary'} />

                            }
                            {item.Banner_CTA_2_Label !== null && item.Banner_CTA_2_URL !== null &&
                              <GetURL label={item.Banner_CTA_2_Label} alias={item.Banner_CTA_2_URL?.Alias} class="btn btn-primary" />
                            }
                            {item.Banner_CTA_2_Label === 'Send Us Your CV' ? <a onClick={item.executeScroll} className="btn btn-primary">{item.Banner_CTA_2_Label}</a> : ''}
                          </div>
                        </StyleRoot>

                      </div>
                      {item.Show_Review ?
                        <StyleRoot>
                          <div style={styles.bounceInLeft} className="rating-block d-none d-lg-block">
                            <GoogleRating />
                          </div>
                        </StyleRoot>
                        : null}



                    </Container>
                  </div>


                </div>))}
            </Slider>
            :
            <div className="main-banner">
              <div className="main-banner-embed">

                {props.areaGuide ?
                  <ImageTransform imagesources={props?.Banner_Image?.url} renderer="srcSet"
                    imagename='area-guide.Banner_Image.details'
                    attr={{ alt: props.Banner_Image?.alternativeText, className: '' }}
                    imagetransformresult={processedImages} id={props?.id} />
                  :
                  <img src={props.Banner_Image?.url_sharp ? getSrc(props.Banner_Image?.url_sharp) : props?.Banner_Image?.url} alt={props.BannerImage?.alternativeText} />
                }

              </div>
              <div className="main-banner-content">
              {props.Embed_Video_URL ?
                        <CustomVideoPlay title={props.Title} url={props.Embed_Video_URL}/> 
                        : '' } 
                <Container>
                  <div className="banner-text">
                    <ScrollAnimation animateIn='fadeIn' animateOnce={true} animateOut='fadeOut'>
                      <h1>{props.Title}</h1>
                    </ScrollAnimation>

                    <StyleRoot>
                      <div className="rich-text-editer-block">
                        <ReactMarkdown style={styles.bounceInLeft} source={props.Banner_Content} allowDangerousHtml />
                      </div>
                    </StyleRoot>

                    <StyleRoot>
                      <div className="btn-wrap" style={styles.bounceInRight}>
                        {props.Banner_CTA_1_Label !== null && props.Banner_CTA_1_URL !== null &&
                          <GetURL label={props.Banner_CTA_1_Label} alias={props.Banner_CTA_1_URL?.Alias} class={thePath=='/' ?'glow-on-hover':'btn btn-primary'} />

                        }
                        {props.Banner_CTA_2_Label !== null && props.Banner_CTA_2_URL !== null &&
                          <GetURL label={props.Banner_CTA_2_Label} alias={props.Banner_CTA_2_URL?.Alias} class="btn btn-primary" />
                        }
                        {props.Banner_CTA_2_Label === 'Send Us Your CV' ? <a onClick={props.executeScroll} className="btn btn-primary">{props.Banner_CTA_2_Label}</a> : ''}
                      </div>
                    </StyleRoot>

                  </div>
                  {props.Show_Review ?
                    <StyleRoot>
                      <div style={styles.bounceInLeft} className="rating-block d-none d-lg-block">
                        <GoogleRating />
                      </div>
                    </StyleRoot>
                    : null}



                </Container>
              </div>


            </div>
          }
        </>
      }
    </React.Fragment>
  )
}

export default Banner