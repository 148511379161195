import { Link } from "@StarberryUtils"
import React,{useState,useEffect} from "react"
import { Container, Row, Col } from 'react-bootstrap';
import './Tab.scss';
import ReactMarkdown from "react-markdown"
import { AREA_GUIDES_PAGE_URL } from "@Components/common/site/constants";
const Tab = (props) => {
const [active, activeurl] = useState('')

const handleScroll = (e) => {

}
useEffect(() => {
  window.addEventListener("scroll", handleScroll)
  return () => {
    window.removeEventListener("scroll", handleScroll)
  }
}, [])
  return (
    <div className="tab-content static-page-content">
      <Container>
        <Row>
          <Col md="3">
          <div className="property-quote tab-view">
            <ul>
              {props.Content && props.Content.length > 0 ?
                props.Content.map(item => {
                  return (
                    <li className={active === item?.Tab_Title?.replace(' ', '_') ? 'active' : ''}><Link onClick={() => activeurl(item?.Tab_Title?.replace(' ', '_'))} to={AREA_GUIDES_PAGE_URL.alias+'/'+props.url+'#'+item?.Tab_Title?.replace(' ', '_')}>{item?.Tab_Title}</Link></li>
                  )
                }) : ''}
            </ul>
            </div>
          </Col>

          <Col md={{ span: 8, offset: 1 }}>
            {props.Content && props.Content.length > 0 ?
              props.Content.map(item => {
                return (
                  <div 
                  style={{scrollMarginTop:'120px'}}
                   className={props.fullwidth? "static-page-content-block fullwidth" : "static-page-content-block"} onScroll={(e) => activeurl(item?.Tab_Title?.replace(' ', '_'))} id={item?.Tab_Title?.replace(' ', '_')}>
                    <div className="rich-text-editer-block">
                    <ReactMarkdown source={item?.Tab_Content} allowDangerousHtml />
                    </div>
                    {item.Tab_Image?.url ?
                    <div className="image-blk">
                      <img src={item.Tab_Image?.url} alt={item.Tab_Image?.alternativeText} />
                    </div>
                    : null}
                  </div>
                )
              }) : ''}
          </Col>
        </Row>

      </Container>
    </div>
  )
}



export default Tab